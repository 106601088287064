export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/admin/dashboard": [7,[2]],
		"/(app)/dashboard": [8,[2]],
		"/(app)/detalles": [9,[2]],
		"/(auth)/logindb": [14,[5]],
		"/(auth)/login": [13,[5]],
		"/(app)/organizations": [10,[2,3]],
		"/(auth)/signin": [15,[5]],
		"/(app)/subidas": [11,[2]],
		"/(app)/users": [12,[2,4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';